.Hint {
    grid-area: 1 / 1 / 5 / 5;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 5;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(30px);

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    padding-bottom: 3em;
    padding-top: 1em;
}

.Hint h1 {
    margin: 1.5em 0;
}

.Hint h2 {
    margin: 0 0 1.5em 0;
    padding: 0 0.1em;
    border-bottom: 5px dotted rgba(255, 255, 255, 1);
}

.Hint ol {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 0 1em;
    margin: 0;
    list-style-position: inside;



    width: 80%;
    max-width: 500px;
    min-width: 300px;
    font-size: 1.5em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.Hint ol > * {
    box-sizing: border-box;
}

.Hint ol > img {
    width: 30%;
    max-height: 8em;
    margin: 1em 5%;
}

.Hint ol > li {
    width: 50%;
    line-height: 1.3em;
}

.Hint ol > li > b {
    border-bottom: 5px dotted rgba(255, 255, 255, 1);
}


.Hint button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    display: block;
    box-sizing: border-box;
    font-weight: bold;
    cursor: pointer;
    
    padding-top: 2em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    border-bottom: 5px dotted rgba(255, 255, 255, 1);
    font-size: inherit;
    background: none;
    text-align: center;
    color: white;
    font-size: 1.5em;
    text-decoration: dashed;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.Logo {
    max-width: 300px;
    min-width: 200px;
    width: 50%;
}