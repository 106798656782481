body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  min-width: 300px;
  min-height: 300px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

button {
  -webkit-appearance:     none;
  -moz-appearance:        none;
  -ms-appearance:         none;
  -o-appearance:          none;
  appearance:             none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  display: block;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
