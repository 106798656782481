.SubmissionForm {
    grid-area: 1 / 1 / 4 / 4;
    backdrop-filter: blur(20px);
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    align-items: center;
    justify-items: center;
    padding-bottom: 1em;

    overflow-y: auto;
    gap: 4em 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.BackButton {
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: none;
    outline: none;
    display: block;
    box-sizing: border-box;
    /**Set pointer**/
    cursor: pointer;


    padding-top: 1em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    border-bottom: 3px dotted rgba(255, 255, 255, 1);
    font-size: inherit;
    background: none;
    text-align: center;
    color: white;
    font-size: 1.5em;
    text-decoration: dashed;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.SubmissionSideContainer {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-size: 1.5em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.SubmissionSideDiv {
    display: flex;
    width: 50%;
    max-width: 420px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.SubmissionNameContainer {
    width: 50%;
    max-width: 420px;
    min-width: 300px;
    text-align: center;
}

.SubmissionNameContainer label {
    position: relative;
    display: block;
    width: 100%;
    height: 3em;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.SubmissionNameContainer label svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1em;
}

.SubmissionNameContainer > *:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.SubmissionNameContainer > *:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.SubmissionNameContainer input {
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: none;
    outline: none;

    width: 100%;
    height: 3em;
    margin: 0;
    padding: 0;
    padding-left: 3em;
    padding-right: 3em;
    background-color: transparent;
    font-size: inherit;
    color: white;
    box-sizing: border-box;
}

.SubmissionNameContainer input:focus {
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.6);
}

.SubmissionNameContainer input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.SubmissionNameContainer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.SubmissionNameContainer ul:empty {
    display: none;
}

.SubmissionForm li {
    display: block;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    height: 3em;
    line-height: 3em;
    margin: 0;
    padding: 0;
    padding-left: 3em;
    padding-right: 3em;
    box-sizing: border-box;
}

.SubmissionForm li:nth-child(n + 1) {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.SubmissionForm li:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 0.4);
}

.SubmitButton {
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: none;
    outline: none;

    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    color: black;
    font-size: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.SubmitButton:hover {
    background-color: rgba(255, 255, 255, 1);
}


.SubmissionSideContainer {
    position: relative;
}

.SubmissionSideContainer > input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
}

.SubmissionSideContainer > input ~ span > svg > path {
    fill: rgb(255, 255, 255, 0.0);
}

.SubmissionSideContainer > input:checked ~ span > svg > path {
    fill: rgb(255, 255, 255);
}


.NewUser {
    display: block;
    box-sizing: border-box;
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: none;
    outline: none;
    font-size: inherit;
    height: 3em;
    width: 100%;
    max-width: 420px;
    min-width: 300px;
    margin: 0;
    padding: 0;
    padding-left: 3em;
    padding-right: 3em;
    background-color: rgba(255, 255, 255, 0.4);
    color: black;
    font-weight: bold;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}


.NewUser:hover {
    background-color: rgb(255, 255, 255)
}