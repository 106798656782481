.Sync {
    grid-area: 1 / 1 / 4 / 4;
    z-index: 5;
    width: 100%;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;
    gap: 2em;


    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(30px);
}

.Sync h2 {
    color: rgba(255, 255, 255, 0.5);
}

.Sync .cancel {
    background-color: transparent;
    border-bottom: 5px dotted white;
    color: white;
    font: inherit;
    font-size: 1.5em;
    font-weight: bold;
}

.progress-table {
    border-collapse: collapse;
    table-layout: fixed;
    padding: 0;
    max-width: 420px;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    border: none;
    background: transparent;
}

.progress-table thead {
    border: none;
}

.progress-table tbody {
    border: none;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.progress-table th, .progress-table td {
    padding: 0em 2em;
    height: 3em;
    margin-bottom: 1em;
    box-sizing: border-box;
    text-align: left;
    color: white;
    font-weight: normal;
    font: inherit;
}

.progress-table th:first-child {
    width: 25%;
}
.progress-table th:nth-child(2) {
    width: 25%;
}
.progress-table th:last-child {
    width: 50%;
}



.progress-table thead th {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.progress-table tbody td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.progress-table tbody tr:last-child td {
    border-bottom: none;
}

.progress-table th {
    font-weight: bold;
}

.progress-table th:first-child, .progress-table td:first-child {
    border-left: none;
}

.progress-list > li {
    padding: 0;
    width: 100%;
}


.progress-item {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5em 0;
}

.progress-item > li {
    width: 50%;
    border-left: 2px solid rgba(255, 255, 255, 0.2);
    padding: 0 1.5em;
    box-sizing: border-box;
}

.progress-item > li:first-child {
    border-left: none;
}

.progress-item > li:last-child {
    width: 100%;
}

.failed {
    color: rgb(219, 65, 65);
    font: inherit;
}

.failed  button {
    font: inherit;
    display: inline-block;
    color: white;
    background-color: rgb(219, 65, 65);
    border-radius: 6px;
    padding: 0.15em 0.4em;
    font-weight: bold;
}

.progress {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    height: 0.3em;
    border-radius: 0.15em;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.progress-bar {
    height: 0.3em;
    border-top-left-radius: 0.15em;
    border-bottom-left-radius: 0.15em;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
}
