.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/**
  * Set div as grid container, set column and row sizes, and more!
  */

.camera {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 4em 120px;
  color: #ffffff;
}


.camera > video {
  grid-area: 1 / 1 / 5 / 5;
  justify-self: center;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  mask-image: url(rectangle.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}


.camera > .ButtonCaptureContinue {
  grid-area: 3 / 1 / 4 / 4;
  /* align to center */
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 3;
}

.device-list {
  grid-area: 3 / 3 / 4 / 4;
  
  display: grid;
  font-size: 17px;
  grid-auto-columns: 2em;
  grid-auto-rows: 2em;


  justify-self: center;
  align-self: center;
  width: max-content;
  height: min-content;
  max-width: 100%;
  max-height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
  border-radius: calc(1em + 5px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

@media screen and (orientation: landscape) {
  .camera {
    grid-template-columns: 1fr 4em 120px;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .camera > .ButtonCaptureContinue {
    grid-area: 2 / 3 / 3 / 4;
  }
  .device-list {
    grid-area: 2 / 2 / 3 / 3;
    grid-auto-flow: row;
  }
}

@media screen and (orientation: portrait) {
  .camera {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 4em 120px;
  }
  .camera > .ButtonCaptureContinue {
    grid-area: 3 / 2 / 4 / 3;
  }
  .device-list {
    grid-area: 2 / 2 / 3 / 3;
    grid-auto-flow: column;
  }
}




.container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}

.container span {
  cursor: pointer;
  text-align: center;
  padding-left: 0.22em;
}

.container:hover span {
  background-color: rgba(255, 255, 255, 0.4);
}

.container:has(input:checked) {
  background-color: white;
  color: black;
}

.hint-container {
  grid-row: 3;
  grid-column: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.hint-button {
  -webkit-appearance:     none;
  -moz-appearance:        none;
  -ms-appearance:         none;
  -o-appearance:          none;
  appearance:             none;
  cursor: pointer;
  border: none;
  outline: none;
  display: block;
  box-sizing: border-box;
  font-weight: bold;
  padding-left: 0.1em;
  padding-right: 0.1em;
  font-size: 1.2em;
  background: none;
  text-align: center;
  color: white;
  text-decoration: dashed;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}



.hand-outline {
  grid-area: 1 / 1 / 5 / 5;
  width: 100%;
  height: 100%;
  z-index: 2;
  /*background-color: rgba(0, 0, 0, 0.4);
  mask-image: url(hand_outline.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;*/
}

.button-bar {
  grid-area: 1 / 3 / 4 / 4;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}


@media screen and (orientation: landscape) {
  .button-bar {
    grid-area: 1 / 3 / 4 / 4;
  }
  .hint-container {
    grid-row: 1;
    grid-column: 3;
  }
}

@media screen and (orientation: portrait) {
  .button-bar {
    grid-area: 3 / 1 / 4 / 4;
  }
  .hint-container {
    grid-row: 3;
    grid-column: 3;
  }
}

.animate > svg {
  animation: rotate 1.5s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.problem {
  color: rgb(207, 65, 65);
}
