.Gallery {
    grid-row: 3;
    grid-column: 3;
    z-index: 3;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 18fr 1fr;
    grid-template-rows: 1fr 18fr 1fr ;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    grid-gap: 0;
}

@media screen and (orientation: landscape) {
    .Gallery {
        grid-row: 3;
        grid-column: 3;
    }
}

@media screen and (orientation: portrait) {
    .Gallery {
        grid-row: 3;
        grid-column: 1;
    }
}

.Gallery:empty {
    display: none;
}

.Gallery li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Gallery li::after {
    display: none;
}

.Gallery li {
    display: none;
    width: 70px;
    height: 70px;
}

.Gallery li:last-child {
    grid-column: 2;
    grid-row: 2;

    display: flex;
    opacity: 1;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.Gallery li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    /*add a shadow to the images*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.Gallery li button {
    display: none;
}




.Gallery-maximal {
    grid-area: 1 / 1 / 4 / 4;
    z-index: 5;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    
    scroll-snap-type: x mandatory;
    background-color: rgba(0, 0, 0, 1);
}

.Gallery-maximal li {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    position: relative;
    scroll-snap-align: center;
}

.Gallery-maximal li img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/*
.Gallery-maximal {
    grid-area: 1 / 1 / 4 / 4;
    z-index: 5;
    padding: 0;
    margin: 0;
    justify-self: center;
    align-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    backdrop-filter: blur(20px);
    overflow-y: auto;
}

.Gallery-maximal li {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: center;
    position: relative;
    width: calc(50% - 2em);
    height: calc(50% - 2em);
    margin: 1em;
    justify-self: center;
    align-self: center;
}

.Gallery-maximal li div {
    width: max-content;
    height: max-content;
}

.Gallery-maximal li img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}*/

.Gallery-maximal li button {
    display: block;
    position: absolute;
    bottom: 0;
    right: 50%;
    font-size: 1.5em;
    height: 2em;
    width: 50%;
    max-width: 210px;
    color: white;
    border: none;
    font-weight: bold;
    padding: 0.5em;
    cursor: pointer;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);
}

.Gallery-maximal li button:first-of-type {
    transform: translate(0, 0);
    border-top-left-radius: 6px;
    background-color: rgba(255, 0, 0, 0.66);
    backdrop-filter: blur(10px);
}
.Gallery-maximal li button:last-of-type {
    transform: translate(100%, 0);
    border-top-right-radius: 6px;
    background-color: rgba(0, 0, 0, 0.33);
}



